import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps';
import RoomIcon from '@material-ui/icons/Room';
import { geoPatterson } from 'd3-geo-projection';
import './roadshow.scss';
import { Box, Grid, Card, CardActionArea, Paper, Fade, Popper } from '@material-ui/core';
import FlatMap from './flatmap.json';
import ImageContainer from '../image-container/index';
const useStyles = makeStyles((theme) => ({
  map: {
    '& svg g': {
      fill: theme.palette.type === 'dark' ? theme.palette.text.disabled : theme.palette.primary.main,
    },
  },

  cardPaper: {
    width: '10vw',
    minWidth: '100px',
  },
  cornerCardBorder: {
    height: '100%',
    padding: theme.spacing(1),
    border: '2px solid',
    borderRadius: '2px',
    backgroundColor: theme.palette.background.default,
    borderColor: `${theme.palette.primary.main} !important`,
    '&::before': {
      backgroundColor: theme.palette.background.default,
    },
    '&::after': {
      backgroundColor: theme.palette.background.default,
    },
  },
}));

export default function RoadShowMap({ appear = false, mapLocations, page = false, all = 0 }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [partnerDetails, setPartnerDetails] = useState(null);
  const [popperAppeaer, setPopperAppear] = useState(false);

  useEffect(() => {
    // const map = document.querySelector('.rsm-svg');
    // const rect = document.querySelector('.rsm-svg g rect');
    const geography = document.querySelector('.rsm-geographies');
    geography.style.transform = 'translateX(-100px)';
    // map.setAttribute('viewBox', '0 0 100 600');
    // if (rect) rect.setAttribute('width', '900');
  }, []);

  const mouseLeave = useCallback((event, code) => {
    if (event) clearDetails();
  }, []);

  const clearDetails = useCallback(() => {
    setPartnerDetails(null);
    setPopperAppear(false);
  }, []);

  const handleShowDetails = useCallback(
    (details) => (event) => {
      if (!partnerDetails) {
        setAnchorEl(event.currentTarget);

        setPartnerDetails(details);
      }
    },
    [partnerDetails]
  );

  useEffect(() => {
    if (partnerDetails) setPopperAppear(true);
  }, [partnerDetails]);

  const projection = geoPatterson().scale(130);

  return (
    <Box className={classes.map} justifyContent='center'>
      {/* <FadeReveal bottom> */}
      {!page && (
        <Grid container>
          {/* <Grid item container xs={12} justify='flex-end'>
            <Link to='/connect'>
              <Typography variant='button' color='primary' align='right'>
                {`View All (${all})`}
              </Typography>
            </Link>
          </Grid> */}
          <Box mb={3} display={{ md: 'block', lg: 'none' }}>
            {/* <Grid item xs={12}>
              <Typography variant='h6'>{partnerDetails.title}</Typography>
              <Typography className='countryText' color='textSecondary' variant='body1'>
                {partnerDetails.text}
              </Typography>
            </Grid> */}
          </Box>
        </Grid>
      )}

      <ComposableMap
        projection={projection}
        projectionConfig={{
          rotate: [-100, 0, 0],
        }}>
        {/* <ZoomableGroup
          disablePanning={false}
          disableZooming={false}
          // minZoom={0.5}
          // maxZoom={2}
          zoom={position.zoom}
          // center={position.coordinates}
          // onMoveEnd={handleMoveEnd}
        > */}
        {/* 'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json' */}
        <Geographies geography={FlatMap}>
          {({ geographies }) =>
            geographies.map((geo, idx) => (
              <Geography
                key={geo.rsmKey + idx}
                geography={geo}
                style={{
                  default: { outline: 'none' },
                  hover: { outline: 'none' },
                  pressed: {
                    outline: 'none',
                  },
                }}
              />
            ))
          }
        </Geographies>
        {/* <ZoomableGroup zoom={1}> */}
        {mapLocations &&
          mapLocations.length > 0 &&
          mapLocations[0].point &&
          mapLocations?.map((_pr, idx) => {
            let newCoordinates = [..._pr.point.coordinates].reverse();
            newCoordinates[0] -= 47;
            newCoordinates[1] += 7;
            return (
              <Marker key={_pr.code} coordinates={newCoordinates} aria-describedby={`popOver${_pr.code}`}>
                <RoomIcon
                  onMouseOver={handleShowDetails(_pr)}
                  onMouseOut={(event) => mouseLeave(event, _pr.code)}
                  onTouchStart={handleShowDetails(_pr)}
                  onTouchCancel={clearDetails}
                  onClick={handleShowDetails(_pr)}
                  color='primary'
                  className='cursorPointer'
                  viewBox={newCoordinates}
                  fontSize='small'
                />
              </Marker>
            );
          })}
        {/* </ZoomableGroup> */}
      </ComposableMap>

      {popperAppeaer && partnerDetails && (
        <Popper open={popperAppeaer} anchorEl={anchorEl} placement='top' transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={10}>
              <Paper className={classes.cardPaper}>
                <Card elevation={0} className='mapCard'>
                  {/* <a href={partnerDetails.websiteUrl ? 'https://' + partnerDetails.websiteUrl : '#'} target='_blank'> */}
                  <Box className={[classes.cornerCardBorder, 'cornerCardBorder'].join(' ')}>
                    <CardActionArea className='zIndex-1'>
                      {partnerDetails && partnerDetails.file && (
                        <img
                          width='100%'
                          src={`${process.env.GATSBY_CMS_API_URL}/static/${partnerDetails.file.code}`}
                          alt={partnerDetails.file.code}
                        />
                      )}

                      {!partnerDetails.file && <ImageContainer filename='default' alt='default' />}
                    </CardActionArea>
                  </Box>
                  {/* </a> */}
                </Card>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </Box>
  );
}
